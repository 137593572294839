import React, { useEffect, useState } from "react";
import { Col, Row, Form, FormGroup, Label, ButtonToggle, FormFeedback, FormText } from 'reactstrap';
import "../authStyle.css";
// import Logo from "../../../asset/icon.jpg";
import { connect } from 'react-redux';
import { Actions } from "../../../initialize/modules/Actions";
import { useHistory } from "react-router-dom";
import { TextField, InputAdornment, IconButton, Button, Input, InputLabel, FormControl, colors, Grid, Card, CardContent, Typography, FilledInput } from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { appHistory } from "../../../initialize/modules/store";
import _ from "lodash";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Logo from "../../../asset/login-hot-logo.jpg";

const { orange } = colors;

const Login = ({ loginUser, authData, loadingAction, appinit ,changePassword}) => {
    const history = useHistory();
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [email, setEmail] = useState('');
    const [loadingData, setLoading] = useState(false);
    const [isEmailValid, setIsEmailValid] = useState(false);
    const [isEmail, setIsEmail] = useState(true);
    const [isPasswordValid, setIsPasswordValid] = useState(false);
    const [isPassword, setIsPassword] = useState(true);
    const [isLogin, setIsLogin] = useState(true);

    //const { innerWidth: width, innerHeight: height } = window;
    const { innerWidth: width, innerHeight: height } = 0;

    useEffect(() => {
        appinit()
    }, [])

    useEffect(() => {
        if (authData?.status) {
            history.push('/')
        }

    }, [authData])

    const onChangePassword = (event) => {
        const text = event.target.value;
        const isValid = text.trim().length > 4;
        setPassword(event.target.value);
        setIsPassword(true);
        setIsPasswordValid(isValid);
    };

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const onForgotPW = () => {
        setIsLogin(!isLogin)
    }

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const onPress = () => {
        //    history.push('/home')
        if (isEmailValid && isPasswordValid) {
           if(isLogin){
            loginUser({ email, password });
           }else{
            onGetPassword() ;
            changePassword({email,password})
           }
        } else {
            if (!isEmailValid) {
                setIsEmail(false)

            }
            if (!isPasswordValid) {
                setIsPassword(false)

            }
        }

    }

    const onChangeEmail = (event) => {
        const text = event.target.value;
        const reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,8})$/;
        let isValid = false;

        if (text.trim().length > 3) {
            isValid = reg.test(text.trim());
        }
        setEmail(event.target.value)
        setIsEmailValid(isValid);
        setIsEmail(true)
    };

    useEffect(() => {
        const { loading, action } = loadingAction;
        const isLoading = loading && action.type == 'LOGIN_USER';
        if (loadingData && !isLoading) {
            //  history.push('/')
        }
        setLoading(isLoading)
    }, [loadingAction]);

    const onGetPassword = ()=>{
        setIsLogin(true);
    }


    return (
        <div >
            {/* need to write style sheet */}
            <Grid container className={'authHead'} />
            <div className={'authTopRow'}>
                <Grid container >
                    <Grid className={'authFirstCol'} xs={12} item>
                    </Grid>
                </Grid>
            </div>
            <div style={{ height: height }} className={'authBottomRow'}>

                <Grid xs={11} sm={10} md={6}>

{/* <TextField id="outlined-basic" label="Outlined" variant="outlined" />
<TextField id="filled-basic" label="Filled" variant="filled" />
<TextField id="standard-basic" label="Standard" variant="standard"  /> */}

                     <Card className="shadow-none">
                        <div style={{ justifyContent: "center", alignItems: "center", display: 'flex', paddingTop: '25px' }}>

                            <Typography className={'typo'} gutterBottom variant="h5" component="div">
                                <div className={"appLogo"}><img src={Logo} className={"logImg"} /></div>

                                <div>VSCA Team Sheet </div>
                                <br></br>
                               {/* {isLogin? 'Sign In': 'Forgot Password'} */}
                            </Typography>
                        </div>
                        {/* {isLogin ? */}

 
                        
                         <CardContent style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>

                            <div>
                                <TextField
                                    label="Email"
                                    type="text"
                                    onChange={onChangeEmail}
                                    value={email}
                                    error={!isEmail}
                                    className={'authTextInput'}
                                    variant="filled"
                                    helperText={isEmail ? "" : "Email is invalid, please add valid email"}
                                />
                            </div>
                            <div>
                                <FormControl  className={'authTextInput'} variant="filled">
                                    <InputLabel htmlFor="filled-adornment-password">Password</InputLabel>
                                    <FilledInput
                                        id="filled-adornment-password"
                                        type={showPassword ? 'text' : 'password'}
                                        value={password}
                                        onChange={onChangePassword}
                                        helperText={isPassword ? "" : "Password is invalid, please add minimum 5 character"}
                                        error={!isPassword}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                >
                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                    />
                                    {!isPassword && <div>
                                        <p className="pwErrorColor">Password is invalid, please add minimum 5 character</p>
                                    </div>}
                                </FormControl>
                            </div>
                            {/* <div>
                                <FormControl className={'authTextInput'}>
                                    <InputLabel htmlFor="standard-adornment-password">{isLogin?'Password':'New Password'}</InputLabel>
                                    <Input
                                        id="filled-adornment-password"
                                        onChange={onChangePassword}
                                        value={password}
                                        label={'Password'}
                                        type={showPassword ? 'text' : 'password'}
                                        error={!isPassword}
                                        helperText={isPassword ? "" : "Password is invalid, please add minimum 5 character"}
                                        endAdornment={
                                            <InputAdornment
                                                position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                >
                                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        } />
                                    {!isPassword && <div>
                                        <p className="pwErrorColor">Password is invalid, please add minimum 5 character</p>
                                    </div>}
                                </FormControl>

                            </div> */}
                            <div>
                                <Button onClick={onPress} variant="contained" color="primary" className={'authButtonStyle'}>
                                   {isLogin? 'Log In':'Change Password'}
                                </Button>
                            </div>
                            <div onClick={onForgotPW} className={'foggotPassword'}>{isLogin?'Forgot password? ':<div>Go Back </div>}</div>

                            <div className="fotter-text">Victorian Sunday Cricket Association | 2020-2024 - V0.7</div>
                        </CardContent> 
                         {/* :
                             <ForgotPassword onPress={onGetPassword} {...{email,onChangeEmail,isEmail}} />} */}
                    </Card>
                </Grid>
            </div>
        </div>
    )
};

export default connect(
    state => ({
        authData: state.auth.get("authData"),
        loadingAction: state.common.get("loadingAction"),
    }),
    {
        loginUser: Actions.auth.loginUser,
        appinit: Actions.auth.appinit,
        changePassword:Actions.auth.changePassword
    }
)(Login);


const ForgotPassword = ({ email = '', isEmail = true, onChangeEmail = () => { }, onPress = () => { } }) => {
    return (
        <CardContent style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
            <div >
                <TextField
                    label="Email"
                    type="text"
                    onChange={onChangeEmail}
                    value={email}
                    error={!isEmail}
                    className={'authTextInput'}
                    helperText={isEmail ? "" : "Email is invalid, please add valid email"}
                />
            </div>
            <div>
                <Button onClick={onPress} variant="contained" color="primary" className={'authButtonStyle'}>
                    Change Password..
                </Button>
            </div>
            <div className="fotter-text">Victorian Sunday Cricket Association | 2021 - V 0.2</div>
        </CardContent>

    )
}