import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import { Actions } from "../../../initialize/modules/Actions";
import _ from "lodash";
import { useHistory } from "react-router-dom";
import { PhotoUploadModal } from "../../../component";
import { Button, MenuItem, FormControl, Select, InputLabel, Grid, Menu, Paper, Card, CardContent, Avatar } from '@mui/material';
import Swal from 'sweetalert2';
import { getImage } from "../../../initialize/service/navigation";
import { Typography } from "@material-ui/core";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { CenterFocusStrong } from "@material-ui/icons";
const MAX_PLAYER = 11;
const MIN_PLAYER = 7;

const OtherScreen = ({ authData, appinit, getGradeData, getRoundData, gradeList, roundList, playerList, postMatchData, updateList, getFixture, fixtureList, delegatesList, getDelegateDetailForCaptain, allClubs }) => {
    const history = useHistory();
    const [selectedRound, setSelectedRound] = useState('');
    const [rounds, setRounds] = useState([]);
    const [grades, setGrades] = useState([]);
    const [fixtures, setFixtures] = useState([]);
    const [selectedGrade, setSelectedGrade] = useState('');
    const season = 17; //useState(`${new Date().getFullYear()}/${new Date().getFullYear() + 1}`);
    const [players, setPlayers] = useState([]);
    const [seasonId, setSeasonId] = useState('2024/2025'); //useState(`${new Date().getFullYear()}/${new Date().getFullYear() + 1}`);
    const [deleData, setDeleData] = useState([]);
    const [clubs, setClubs] = useState({});
    const [currentClubName, setCurrentClubName] = useState('');

    const [selectedFixture, setSelectedFixture] = useState('');

    useEffect(() => {
        setPlayers(playerList);
    }, [playerList]);

    useEffect(() => {
        setRounds(roundList);
    }, [roundList]);

    // useEffect(() => {
    //     setGrades(gradeList);
    // }, [gradeList]);

    useEffect(() => {
        const list = _.filter(gradeList, d => _.findIndex(delegatesList, i => i.grade_id == d.id) > -1);
        setGrades(list);
    }, [delegatesList, gradeList]);

    useEffect(() => {
        const list = _.filter(fixtureList, item => item.status !== 'CONFIRMED');
        // setFixtures(list);
    }, [fixtureList]);

    useEffect(() => {
        const obj = _.groupBy(allClubs, 'id');
        setCurrentClubName(_.get(obj, `[${_.get(authData, 'clubid', '')}][0].company`, ''))
        setClubs(obj);
    }, [allClubs])

    useEffect(() => {

        getRoundData();
        getGradeData();
        getFixture();
        getDelegateDetailForCaptain(authData.cricketid)
        const dele = localStorage.getItem('DELEGATE');
        setDeleData(JSON.parse(dele));

        let selectedRound = localStorage.getItem('selectedRound');
        selectedRound = JSON.parse(selectedRound);
        let selectedGrade = localStorage.getItem('selectedGrade');
        selectedGrade = JSON.parse(selectedGrade);
        let selectedFixture = localStorage.getItem('selectedFixture');
        selectedFixture = JSON.parse(selectedFixture);
        if (!_.isNull(selectedFixture)) {
            setSelectedFixture(selectedFixture)
        }
        if (!_.isNull(selectedGrade)) {
            setSelectedGrade(selectedGrade);
        }
        if (!_.isNull(selectedRound)) {
            setSelectedRound(selectedRound)
        }



    }, [])

    useEffect(() => {
        if (!authData?.status) {
            appinit()
        }
        _.delay(() => {
            if (!authData?.status) {
                history.push('/login')
            }
        }, 100)
    }, [authData]);

    const filterFixtures = (gradeId, roundId) => {
        const list = _.filter(fixtureList, item => item.grade_id == gradeId && item.round_id == roundId && item.status !== 'CONFIRMED' && (item.awayteam_club_id == authData.clubid || item.hometeam_club_id == authData.clubid));

        setFixtures(list);
    }

    const handleChange = (event) => {
        setSelectedRound(event.target.value);
        const gradeId = _.get(selectedGrade, 'id', '');
        const roundId = _.get(event, 'target.value.id', '');
        filterFixtures(gradeId, roundId)
        localStorage.setItem('selectedRound', JSON.stringify(event.target.value))
    };

    const onSelectItem = (event) => {
        setSelectedGrade(event.target.value)
        const gradeId = _.get(event, 'target.value.id', '');
        const roundId = _.get(selectedRound, 'id', '');
        filterFixtures(gradeId, roundId)
        localStorage.setItem('selectedGrade', JSON.stringify(event.target.value));
    }

    // fixture
    const fixtureHandleChange = (event) => {
        setSelectedFixture(event.target.value);
        localStorage.setItem('selectedFixture', JSON.stringify(event.target.value))
        // console.log(clubs[event.target.value.awayteam_club_id][0].company,"pppp",event.target.value.awayteam_club_id)
    };

    const onDragEnd = (params) => {
        const sourceIndex = params.source?.index;
        const destinationIndex = params.destination?.index;
        if (destinationIndex) {
            players.splice(destinationIndex, 0, players.splice(sourceIndex, 1)[0]);
            setPlayers(players)
            updateList(players)
        }
    }

    const upLoadData = () => {
        if (selectedRound && selectedRound.id && selectedGrade && selectedGrade.id && authData && authData.id && players && (players.length <= MAX_PLAYER && players.length >= MIN_PLAYER)) {
            const list = playerList.map(item => {
                const id = _.get(item, 'player_id', 0);
                const club_id = authData.clubid;
                const grade_id = selectedGrade.id;
                const season_id = season;
                // const fixture_id = 1;   // - KDK
                const round_id = selectedRound.id;    // - KDK 
                return { id, club_id, grade_id, round_id, season_id, cricketid: _.get(item, 'cid', _.get(item, 'cricketid', '')) }
            });
            const data = {
                info: [
                    {
                        matchid: new Date().getTime(),
                        club_id: authData.clubid,//authData.id,
                        round_id: selectedRound.id,
                        grade_id: selectedGrade.id,
                        season_id: season,
                        fixture_id: selectedFixture.id,
                        user_id:_.get(authData, 'cricketid', '')
                    }
                ],
                players: list,
            };
            // console.log(data);
            postMatchData(data);

        } else {
            let message = 'Please fill all fields';
            if (!selectedRound.id) {
                message = `Please select round`;
            }
            else if (!selectedGrade.id) {
                message = `Please select grade`;
            }
            else if (!authData.id) {
                message = `Invalid authentication, please login with your account credential`;
            }
            else if (players.length < MIN_PLAYER) {

                // KDK - MINIMUM - 2022 July 10 
                message = `Your player list is less than ${MIN_PLAYER}, please check and try again`
            }

            else if (players.length > MAX_PLAYER) {
                message = `Your player list higher than ${MAX_PLAYER} !`
            }

            Swal.fire({
                title: 'Oops',
                text: message,
                animation: true
            })

        }
    }

    const renderItem = ({ item, index }) => {
        const { pimage, cid, email, first_name, last_name, rank , middle_name, other_name, cricketid } = item;
        return (
            <Card style={{ height: 115}}>
                <CardContent>
                    <Grid container spacing={10}>
                        <Grid xs={2} item>
                            <img src={getImage(pimage)} style={{ height: 80, width: 65 }} />
                            {/* <Avatar
                                alt={`${first_name} ${last_name}`}
                                src={getImage(pimage)}
                                sx={{ width: 35, height: 35 }}
                            /> */}
                        </Grid>
                        <Grid xs={8} item>
                            <Typography variant="body2" color="textSecondary" component="p"><h6>{cricketid} </h6></Typography>
                            <Typography variant="body2" color="textSecondary" component="p"><h6>{first_name} {last_name} {middle_name} {other_name} </h6></Typography>
                            <Typography variant="body2" color="textSecondary" component="p">
                                Player points: <b> {rank} </b>
                            </Typography>
                        </Grid>
                    </Grid>

                </CardContent>
            </Card>
            
        )
    }


    return (
        <DragDropContext {...{ onDragEnd }}>
            <div>
                <Paper style={{ paddingTop: '2%', display: "flex", justifyContent: 'center', alignItems: 'center' }}>
                    <h4>Season - {seasonId}</h4>
                </Paper>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={6}>

                        <div className={'otherTextInputBox'}>
                            <FormControl className={'otherTextInputBoxForm'} variant="standard" sx={{ m: 1, minWidth: 120 }}>
                                <InputLabel id="r-round-label">Select Grade</InputLabel>
                                <Select
                                    labelId="r-round-label"
                                    id="demo-simple-select-standard"
                                    value={selectedGrade.name}
                                    onChange={onSelectItem}
                                    label="Select Grade"
                                    className={'otherSelecter'}
                                >

                                    {
                                        grades.map((item, index) => {
                                            return (

                                                <MenuItem
                                                    style={{
                                                        minWidth: 200,
                                                        backgroundColor: selectedGrade.id === item.id ? '#0AA1DD' : '#DFF6FF',
                                                        margin: '5px', paddingLeft:'10px', paddingTop:'10px', display: 'block'
                                                    }}
                                                    value={item}>{_.get(item, 'name', '')}</MenuItem>)
                                        })
                                    }



                                </Select>
                            </FormControl>
                        </div>

                        <div className={'otherTextInputBox'}>
                            <FormControl className={'otherTextInputBoxForm'} variant="standard" sx={{ m: 1, minWidth: 120 }}>
                                <InputLabel id="demo-simple-select-standard-label">Select Round</InputLabel>
                                <Select
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    value={selectedRound.name}
                                    onChange={handleChange}
                                    label="Select Round"
                                    className={'otherSelecter'}
                                >
                                    {
                                        rounds.map((item, index) => {
                                            return <MenuItem
                                                style={{
                                                    minWidth: 200,
                                                    backgroundColor: selectedRound.id === item.id ? '#0AA1DD' : '#DFF6FF',
                                                    margin: '5px', paddingLeft:'10px', paddingTop:'10px', display: 'block'
                                                }}
                                                value={item}>{_.get(item, 'id', '')}</MenuItem>
                                        })
                                    }

                                </Select>
                            </FormControl>
                        </div>

                        <div className={'otherTextInputBox'}>
                            <FormControl className={'otherTextInputBoxForm'} variant="standard" sx={{ m: 1, minWidth: 120 }}>
                                <InputLabel id="fixture_id">Select Match</InputLabel>
                                <Select
                                    labelId="fixture_id"
                                    id="demo-simple-select-standard"
                                    value={selectedFixture.awayteam_club_id}
                                    onChange={fixtureHandleChange}
                                    label="Select Match"
                                    className={'otherSelecter'}
                                >
                                    {
                                        fixtures.map((item, index) => {
                                            const id = _.get(item, 'awayteam_club_id', '');
                                            const id2 = _.get(item, 'hometeam_club_id', '');
                                            const name = _.get(clubs, `[${id2}][0].company`, '') + ' VS ' + _.get(clubs, `[${id}][0].company`, '')

                                            return <MenuItem
                                                style={{
                                                    minWidth: 200,
                                                    backgroundColor: selectedFixture.id === item.id ? '#0AA1DD' : '#DFF6FF',
                                                    margin: '5px', paddingLeft:'10px', paddingTop:'10px', display: 'block'
                                                }}
                                                value={item}>{name}<br></br>{item.name} </MenuItem>
                                        })
                                    }

                                </Select>
                            </FormControl>
                        </div>

                        <div style={{ marginTop: 15 }}>
                            <Grid className={'detail-left-margin'} >
                                <Grid item xs={12} className={'otherTextInputBox'}>
                                    <Button
                                        variant={'contained'}
                                        className={'submitButton'}
                                        onClick={upLoadData}
                                    >Confirm</Button>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                    <Grid style={{ marginBottom: 200 }} item xs={12} sm={6} md={6}>
                        <div className={'detailUserList'}>
                            <div className={""}>

                                <Droppable droppableId="droppable-1" type="PERSON">
                                    {(provided, snapshot) => (
                                        <Paper
                                            style={{ backgroundColor: snapshot.isDraggingOver ? 'lightblue' : 'white' }}
                                            {...provided.droppableProps}
                                            ref={provided.innerRef}
                                        // style={{ marginBottom: '100px' }}
                                        >
                                            <Grid className={'otherTextInputBox'} container spacing={2}>
                                                {
                                                    players.map((item, index) => {
                                                        return (
                                                            <Grid xs={11} sm={11} md={6} lg={4} item >
                                                                <Draggable key={`${index}`} draggableId={"draggable-" + index} index={index}>
                                                                    {(provided, snapshot) => (
                                                                        <div
                                                                            ref={provided.innerRef}
                                                                            {...provided.draggableProps}
                                                                            {...provided.dragHandleProps}
                                                                            className={``} role="alert">

                                                                            {renderItem({ item, index })}

                                                                        </div>
                                                                    )}
                                                                </Draggable>
                                                            </Grid>
                                                        )
                                                    })
                                                }
                                            </Grid>
                                        </Paper>
                                    )}
                                </Droppable>


                            </div>

                        </div>
                    </Grid>
                </Grid>


            </div>
        </DragDropContext>
    )
};

export default connect(
    state => ({
        playerList: state.home.get('playerList'),
        authData: state.auth.get("authData"),
        gradeList: state.home.get("gradeList"),
        roundList: state.home.get("roundList"),
        fixtureList: state.home.get('fixtureList'),
        delegatesList: state.home.get('delegatesList'),
        allClubs: state.home.get('allClubs'),
    }),
    {
        getPlayerData: Actions.home.getPlayerData,
        updateList: Actions.home.updateList,
        getPlayerList: Actions.home.getPlayerList,
        appinit: Actions.auth.appinit,
        createPlayer: Actions.home.createPlayer,
        getRoundData: Actions.home.getRoundData,
        getGradeData: Actions.home.getGradeData,
        postMatchData: Actions.home.postMatchData,
        getFixture: Actions.home.getFixture,
        getDelegateDetailForCaptain: Actions.home.getDelegateDetailForCaptain,
    })(OtherScreen)



